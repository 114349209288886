
.Rank {
  width: 756px;
  height: 108px;
  padding: 24px 30px 0px 30px;
  box-sizing: border-box;
  // justify-content: space-between;
  // align-items: center;
  // flex-wrap: wrap;
  // height: 46px;
  .search {
    box-sizing: border-box;
    width: 696px;
    height: 38px;
    padding: 12px 0px 12px 12px;
    display: flex;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #2885ff;
    align-items: center;
    margin-bottom: 12px;
    .input {
      // height: 34px;
      width: 572px;
      flex: 1;
      border: none;
      outline: none;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      width: 100%;
      &::-moz-placeholder {
        font-size: 14px;
        color: #999999;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #999999;
      }
    }
    > span {
      height: 100%;
      display: flex;
      align-items: center;
      &:active {
        transform: scale(0.96);
      }
    }
    > span .search-icon {
      height: 12px;
      width: 12px;
      margin-right: 8px;
    }
    .search-button {
      cursor: pointer;
      box-sizing: border-box;
      padding: 1px;
      border-radius: 0px 6px 6px 0px;
      width: 72px;
      height: 38px;
      background-color: #2885ff;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      span {
        font-size: 16px;
      }
    }
  }
  .sort {
    height: 34px;
    width: 696px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #101010;
    span {
      margin-left: 68px;
      margin-right: 17px;
    }
    ::v-deep .el-radio {
      margin-right: 17px;
    }
    ::v-deep .el-radio__label {
      color: #101010;
    }
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      color: #101010;
      border-color: #14569f;
      background: #ffffff;
    }
    ::v-deep .el-radio__inner::after {
      background-color: #14569f;
      height: 7px;
      width: 7px;
    }
    ::v-deep .el-tabs__nav-wrap::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0.125rem;
        background-color: #E4E7ED;
        z-index: 1;
    }
    ::v-deep .el-tabs__item {
        color: #8896A7;
        font-size: 14px;
        
    }
    ::v-deep .el-tabs__active-bar {
        background-color: #2885FF;
    }
    ::v-deep .is-active {
        color: #101010;
        font-size: 14px;
    }
    ::v-deep .el-tabs__nav-wrap::after {
        background-color: #FFFFFF;
    }
  }
}

